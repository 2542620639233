
import LoziLogo from '@/components/svg/LoziLogo.vue'
import SmartBarcodeLogo from '@/components/svg/SmartBarcodeLogo.vue'
import { CLEAR_PROFILE, CLOSE_DIALOG, LOGIN } from '@/store/actions'
import { errorHandle } from '@/utils/helpers'
import { openMessage } from '@/utils/utils'
import { maska } from 'maska'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

@Options({
  components: { SmartBarcodeLogo, LoziLogo },
  directives: { maska },
  name: 'Login',
})
export default class Login extends Vue {
  error = ''
  form = {
    email: '',
    password: '',
  }

  requiredMessage = ''

  get isAuth() {
    return !!this.$store.state.profile.user
  }

  get isLoading() {
    return this.$store.getters.isLoading
  }

  @Watch('isAuth')
  onAuthChange() {
    if (this.isAuth) {
      this.gotoDashboard()
    }
  }

  gotoDashboard() {
    this.$router.push({
      name: 'home',
    })
  }

  requiredValidate = (rule: string, value: string, callback: Function) => {
    if (value === '') {
      callback(new Error(this.requiredMessage))
    } else {
      callback()
    }
  }

  async doLogin() {
    this.$store.commit(CLEAR_PROFILE)
    await this.$store
      .dispatch(LOGIN, { ...this.form })
      .then(() => {
        this.$store.commit(CLOSE_DIALOG)
      })
      .catch((e: Record<string, unknown> | string) => {
        if (typeof e === 'string' && e === '2004') {
          openMessage(this.$t('errors.2004'), 'error')
        } else {
          errorHandle(e as Record<string, unknown>, { ...this.form }, false)
        }
      })
  }

  onLogin(formName: string) {
    this.$refs[formName].validate((valid: string) => {
      if (valid) this.doLogin()
      else return false
    })
  }

  rules = {
    email: [{ validator: this.requiredValidate, trigger: 'blur' }],
    password: [{ validator: this.requiredValidate, trigger: 'blur' }],
  }

  mounted() {
    const errCode = this.$route.params.errorCode
    if (!isEmpty(errCode)) openMessage(this.$t(`errors.${errCode}`), 'error')
    this.requiredMessage = this.$t('this_field_is_required')
  }
}
